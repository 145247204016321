<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn tile v-if="permiteAcao($route, 'add')" :color="variables.colorPrimary" dark class="mb-2 modal-cadastro-btn-cadastrar" @click="openModal">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          {{ isEdit ? 'Editar Recursos' : 'Cadastrar Recursos' }}
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="nameErrors" v-model="item.name" label="Nome:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-select :error-messages="idGroupErrors" v-model="item.moduleGroupId" :items="itemGroups" label="Grupo:" @input="$v.item.idGroup.$touch()" @blur="$v.item.idGroup.$touch()"></v-select></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field :error-messages="pathErrors" v-model="item.path" label="Path:" @input="$v.item.path.$touch()" @blur="$v.item.path.$touch()" /></v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line no-unused-vars
import { map, assignIn } from 'lodash'

export default {
  name: 'BrModalCadastroEndpoints',
  mixins: [validationMixin],
  // props: {
  //   item: {
  //     type: Object,
  //     default: () => {
  //       return  {
  //         id: '',
  //         name: '',
  //         itemGRoups: ''
  //       }
  //     }
  //   }
  // },
  data: () => ({
    activeModal: false,
    dadosGroups: null,
    loading: false
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('endpoints', ['item']),
    ...mapGetters('grupos', {itemGroups: 'listaItensSelect'}),
    ...mapGetters('filter', ['objFilter']),
    ...mapGetters('pagination', ['page']),

    nameErrors () {
      const errors = []
      if (!this.$v.item.name.$dirty) return errors
      !this.$v.item.name.required && errors.push('Nome é obrigatório.')
      return errors
    },

    pathErrors () {
      const errors = []
      if (!this.$v.item.path.$dirty) return errors
      !this.$v.item.path.required && errors.push('Path é obrigatório.')
      return errors
    },

    idGroupErrors () {
      const errors = []
      if (!this.$v.item.moduleGroupId.$dirty) return errors
      !this.$v.item.moduleGroupId.required && errors.push('Seleção do grupo é obrigatório.')
      return errors
    },

    variables: () => variables,

    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })
    this.obterItensGroups()
  },
  methods: {
    ...mapActions('endpoints', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    ...mapActions('grupos', {
      obterItensGroups: 'obterItensSelect' 
    }),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      const dados = {
        name: this.item.name.trim(),
        path: this.item.path.trim(),
        moduleGroupId: this.item.moduleGroupId
      }

      if (this.item.id && this.item.id !== null && this.item.id !== '') {
        dados.id = this.item.id
      }
      
      if (dados.id) {
        return this.editarCadastro(dados)
      }

      this.cadastrarItem(dados).then(() => {
        this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, ...this.objFilter })
        this.closeModal()
        this.loading = false
        const msg = {
          toggle: true,
          type: 'success',
          msg: 'Cadastro realizado com sucesso!'
        }
        Events.$emit('snackbarCadastro::msg', msg)
      }).catch(err => {
        this.loading = false
        const msg = {
          toggle: true,
          type: 'error',
          msg: err.error
        }
        Events.$emit('snackbarCadastro::msg', msg)
      })

    },

    editarCadastro (val) {
      this.editarItem(val).then(() => {
        this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, ...this.objFilter })
        this.closeModal()
        this.loading = false
        const msg = {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        }
        Events.$emit('snackbarCadastro::msg', msg)
      }).catch(error => {
        this.loading = false
        const msg = {
          toggle: true,
          type: 'error',
          msg: error.error
        }
        Events.$emit('snackbarCadastro::msg', msg)
      })
    }
  },

  validations () {
    return  {
      item: {
        name: { required },
        moduleGroupId: { required },
        path: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>